import { HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import Dexie from 'dexie';
import { from, Observable } from 'rxjs';

import { DEXIE_CONFIG, DexieConfiguration } from './provider/dexie.config';

@Injectable({
    providedIn: 'root',
})
export class RequestLocalStorageService {
    constructor(
        private db: Dexie,
        @Inject(DEXIE_CONFIG) private dexieConfiguration: DexieConfiguration,
    ) {
        this.initDB();
    }

    /* istanbul ignore next wvk, only wrapping Dexie */
    public getRequests(
        solutionId?: string,
    ): Observable<{ id: number; solutionId: string; request: HttpRequest<any> }[]> {
        const table = this.db.table('offlineRequestQueue');
        const requests = solutionId ? table.where({ solutionId }) : table;
        return from(requests.toArray());
    }

    /* istanbul ignore next wvk, only wrapping Dexie */
    public getRequestCount(solutionId?: string): Observable<number> {
        return from(this.db.table('offlineRequestQueue').where({ solutionId }).count());
    }

    /* istanbul ignore next wvk, only wrapping Dexie */
    public async add(solutionId: string, request: HttpRequest<any>): Promise<any> {
        return this.db.table('offlineRequestQueue').put({ solutionId, request });
    }

    /* istanbul ignore next wvk, only wrapping Dexie */
    public async remove(id: number): Promise<any> {
        return this.db.table('offlineRequestQueue').delete(id);
    }

    /* istanbul ignore next wvk, only wrapping Dexie */
    public async clear(solutionId?: string): Promise<any> {
        const table = this.db.table('offlineRequestQueue');

        if (solutionId) {
            return table.where({ solutionId }).delete();
        } else {
            return table.clear();
        }
    }

    /* istanbul ignore next wvk, only init */
    private initDB(): void {
        // Name only the indexed columns in the store definition
        this.db.version(this.dexieConfiguration.databaseVersion).stores({
            offlineRequestQueue: '++id, solutionId',
        });
    }
}
